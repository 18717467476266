html, body {
  background-color: rgb(38, 69, 131);
  color: whitesmoke;
  margin: 0;
}

* {
  font-family: Arial, Helvetica, sans-serif;
}

h1 {
  margin: 48px;
}

form {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  padding-top: 32px;
  min-height: 100vh;
}

.midPageControls, .roomInfoPanel {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.connectPanel {
  background-color: rgb(25, 44, 81);
  padding: 20px;
  border-radius: 5%;
  margin: 96px 0;
}

.connectedUsers {
  background-color: rgb(25, 44, 81);
  padding: 12px;
  border-radius: 5%;
  margin-bottom: 48px;
}

h2 {
  margin: 12px 32px; 
}

input[type="text"] {
  margin: 8px;
  height: 36px;
  width: 256px;
  font-size: 20px;
  padding-left: 12px;
}

input[type="submit"], .freeBuzzerButton {
  margin: 16px;
  height: 36px;
  width: 128px;
  font-size: 18px;
  border: none;
  background-color: #365fab;
  color: rgb(245, 245, 245);
}

.topButtonBar {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 80px;
  background-color: rgb(38, 69, 131);
}

.leaveButton, .showPasswordButon {
  z-index: 100;
  box-sizing: border-box;
  position: fixed;
  top: 16px;
  height: 48px;
  padding: 4px 12px;
  font-size: 18px;
  border: none;
  color: rgb(245, 245, 245);
}

.leaveButton {
  left: 16px;
  width: 144px;
  background-color: rgb(202, 90, 90);
}

.showPasswordButon {
  right: 16px;
  width: 168px;
  background-color: rgb(36, 36, 36);
}

.showPasswordButon:hover {
  background-color: rgb(49, 49, 49);
}

.leaveButton:hover {
  background-color: rgb(227, 102, 102);
}

.showPasswordButon:active {
  background-color: rgb(22, 22, 22);
}

.leaveButton:active {
  background-color: rgb(176, 79, 79);
}

input[type="submit"]:hover, .freeBuzzerButton:hover {
  background-color: #4274d1;
}

input[type="submit"]:active, .freeBuzzerButton:active {
  background-color: #254277;
}

.adminName {
  color: rgb(255, 204, 0);
}

.buzzerButton {
  background-color: rgb(232, 232, 232);
  border: 14px solid rgb(194, 194, 194);
  margin-top: 64px;
  margin-bottom: 24px;
  padding: 32px;
  font-size: 56px;
  width: 500px;
  height: 500px;
  color: rgb(34, 34, 75);
  border-radius: 100%;
  box-shadow: 6px 6px 14px rgba(0, 0, 0, 0.6);
  transition: transform 100ms, background-color 200ms, border 200ms;
}

.buzzerButton:hover:enabled {
  background-color: rgb(245, 245, 245);
}

.buzzerButton:active:enabled {
  background-color: rgb(218, 218, 218);
  transform: scale(0.99);
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.8);
}

@media only screen and (max-width: 600px) {
  .buzzerButton {
    width: 360px;
    height: 360px;
    font-size: 40px;
  }
}

@media only screen and (min-width: 850px) { 
  .roomInfoPanel {
    position: fixed;
    left: -16px;
    top: 25vh;
  }

  .connectedUsers {
    padding-left: 24px;
  }

  .midPageControls {
    margin-left: 250px;
  }
}

@media only screen and (min-width: 1100px) {
  .midPageControls {
    margin-left: 0;
  }
}

.buzzerButton:disabled {
  color: rgb(50, 50, 112);
}

.green-bg {
  background-color: rgb(111, 227, 102);
  border: 14px solid rgb(86, 176, 79);
  transform: scale(0.99);
  transition: transform 100ms, background-color 200ms, border 200ms;
  box-shadow: none;
}

.red-bg {
  background-color: rgb(227, 102, 102);
  border: 14px solid rgb(176, 79, 79);
  transform: scale(0.99);
  transition: transform 100ms, background-color 200ms, border 200ms;
  box-shadow: none;
}